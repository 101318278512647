import React from "react";
import { Helmet } from "react-helmet";
import ToolLandingTemplate from "./ToolLandingTemplate";

const ActivityRoastLanding = () => {
  const metaTitle =
    "Strava Activity Roast | AI Commentary for Your Strava Workouts";
  const metaDescription =
    "Get witty, personalized commentary on your individual Strava activities. Our AI analyzes your performance, route, and effort to provide entertaining insights about your workouts.";

  const title = "Strava Activity Roast";
  const description =
    "Get witty, personalized commentary on your individual Strava activities. Our AI analyzes your performance, route, and effort to provide entertaining insights about your workouts.";

  const howItWorks = [
    {
      title: "Connect Your Strava",
      description: "Log in to securely link your Strava account.",
    },
    {
      title: "Select an Activity",
      description: "Select an activity from your recent Strava history.",
    },
    {
      title: "Get Your Roast",
      description: "Receive a personalized analysis of your activity.",
    },
  ];

  const faqs = [];

  const otherTools = [
    {
      name: "Profile Roast",
      shortDescription:
        "Get AI commentary on your entire Strava profile and all-time stats",
      path: "/strava-profile-roast",
    },
    {
      name: "Title Generator",
      shortDescription:
        "Create engaging, personalized titles for your Strava activities",
      path: "/strava-title-generator",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <ToolLandingTemplate
        title={title}
        description={description}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        howItWorks={howItWorks}
        howItWorksTitle="How the Strava Activity Roast Works"
        faqs={faqs}
        otherTools={otherTools}
        tryItText="Roast My Activity!"
      />
    </>
  );
};

export default ActivityRoastLanding;
