import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./ToolLandingTemplate.css";
import stravaConnectButton from "../assets/connect_with_strava.png";
import logo from "../assets/logo.png";

const ToolLandingTemplate = ({
  title,
  description,
  metaTitle,
  metaDescription,
  howItWorks,
  howItWorksTitle = "How It Works",
  faqs,
  otherTools,
  tryItText = "Try It",
}) => {
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConnectWithStrava = () => {
    // Reuse existing Strava connection logic
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/strava`;
  };

  const handleTryIt = () => {
    // Navigate to login page - this should match your existing login flow
    navigate("/");
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="tool-landing-container">
      {/* Header */}
      <header className="site-header">
        <Link to="/" className="site-logo">
          <img className="site-logo-img" src={logo} alt="Roast My Strava" />
          Roast My Strava
        </Link>
      </header>

      {/* Hero Section */}
      <section className="hero-section">
        <h1>{title}</h1>
        <p className="description">{description}</p>
        <div className="cta-buttons">
          <img
            src={stravaConnectButton}
            alt="Connect with Strava"
            onClick={handleConnectWithStrava}
            className="strava-connect-btn"
          />
          <button className="try-it-btn" onClick={handleTryIt}>
            {tryItText}
          </button>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section">
        <h2>{howItWorksTitle}</h2>
        <div className="steps-container">
          {howItWorks.map((step, index) => (
            <div key={index} className="step">
              <div className="step-number">{index + 1}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* FAQs Section */}
      {faqs.length > 0 && (
        <section className="faqs-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faqs-container">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* Other Tools Section */}
      <section className="other-tools-section">
        <h2>More AI Tools for Strava</h2>
        <div className="tools-grid">
          {otherTools.map((tool, index) => (
            <Link
              key={index}
              to={tool.path}
              className="tool-card"
              onClick={scrollToTop}
            >
              <h3>{tool.name}</h3>
              <p>{tool.shortDescription}</p>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ToolLandingTemplate;
