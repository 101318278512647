import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import poweredByStrava from "../assets/powered_by_strava.png";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";
// Add custom CSS for the activity selector
import "./ActivitySelector.css";

function Dashboard() {
  const location = useLocation();
  const [selectedFeature, setSelectedFeature] = useState("profile"); // Default to "profile"
  const [roastResult, setRoastResult] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  // const [selectedModel, setSelectedModel] = useState("default");

  const [roastIntensity, setRoastIntensity] = useState(5);
  const [unitPreference, setUnitPreference] = useState("kilometers");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [loadingEmoji, setLoadingEmoji] = useState("🏃‍♂️");
  const athleticEmojis = [
    "🏃‍♂️",
    "🚴‍♀️",
    "🏊‍♂️",
    "🏃🏻‍♀️",
    "🏋️‍♀️",
    "🤸‍♀️",
    "🏄‍♂️",
    "🏃🏿‍♂️",
    "🚣‍♀️",
    "🧘‍♀️",
    "🤾‍♂️",
    "🚴🏾‍♀️",
    "🤼‍♀️",
    "🏇",
    "🤺",
    "🏌️‍♂️",
    "⛹️‍♂️",
    "🏂",
    "🧗‍♀️",
    "🤽‍♂️",
    "🚵‍♀️",
  ];

  const loadingIntervalRef = useRef(null);

  // Separate intense mode states for each feature
  const [isProfileExtraMeanMode, setIsProfileExtraMeanMode] = useState(false);
  // For activity roast, we currently don't have a UI toggle but we use the state in API calls
  // eslint-disable-next-line no-unused-vars
  const [isActivityExtraMeanMode, setIsActivityExtraMeanMode] = useState(false);

  // This backward compatibility variable is no longer used, removing to fix linter warning
  // const isExtraMeanMode =
  //   selectedFeature === "profile"
  //     ? isProfileExtraMeanMode
  //     : isActivityExtraMeanMode;

  const USE_LOCAL_API = "false";
  // const USE_LOCAL_API = "true";

  const [hasGeneratedFirstRoast, setHasGeneratedFirstRoast] = useState(false);

  // States for activity roast
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedActivityDetails, setSelectedActivityDetails] = useState(null);
  const [activityRoastResult, setActivityRoastResult] = useState("");

  // States for title generator
  const [generatedTitle, setGeneratedTitle] = useState("");
  const [titleStyle] = useState("creative");
  const [titleRandomness] = useState(10);
  const [keywords, setKeywords] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  // Add a state variable for search term
  const [activitySearchTerm, setActivitySearchTerm] = useState("");
  const [showActivitySearch, setShowActivitySearch] = useState(false);

  // Update the dropdown state variables to separate activity and title dropdowns
  const [activityDropdownOpen, setActivityDropdownOpen] = useState(false);
  const [titleDropdownOpen, setTitleDropdownOpen] = useState(false);
  const activityDropdownRef = useRef(null);
  const titleDropdownRef = useRef(null);

  const handleIntensityChange = (event) => {
    setRoastIntensity(parseInt(event.target.value, 10));
  };

  const fetchUserProfileAndCheckAuth = useCallback(async () => {
    // console.log("Checking login status and fetching user profile");
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        console.log("No token found, redirecting to home");
        navigate("/");
        return;
      }

      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );

      const userProfileData = response.data;
      // console.log(userProfileData);

      // Proxy the profile image through the backend
      if (userProfileData.profile) {
        userProfileData.profile = `https://roast-my-strava-backend-production.up.railway.app/proxy-image?url=${encodeURIComponent(
          userProfileData.profile
        )}`;
      }

      setUserProfile(userProfileData);
      setUnitPreference(
        userProfileData.country === "United States" ? "miles" : "kilometers"
      );
    } catch (error) {
      console.error("Error fetching user profile:", error);
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 429 ||
          error.response.status === 403)
      ) {
        console.log("Not authenticated, clearing token and redirecting");
        localStorage.removeItem("strava_token");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserProfileAndCheckAuth();
  }, [fetchUserProfileAndCheckAuth]);

  const handleRoast = async () => {
    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(
        USE_LOCAL_API === "true"
          ? "http://127.0.0.1:5000/roast"
          : "https://roast-my-strava-backend-production.up.railway.app/roast",
        {
          params: {
            token,
            intensity: isProfileExtraMeanMode ? 10 : roastIntensity,
            unitPreference,
            model: isProfileExtraMeanMode ? "x" : "default",
            // yearMode: isYearRoastMode ? currentYear : null,
          },
          withCredentials: true,
        }
      );
      setRoastResult(response.data.message);
      setHasGeneratedFirstRoast(true);
    } catch (error) {
      // console.error("Error:", error);
      if (error.response && error.response.status === 429) {
        // Rate limit error
        navigate("/", { state: { error: "rate_limit" } });
      } else if (
        error.response &&
        error.response.data.error === "Error communicating with Strava API"
      ) {
        navigate("/", { state: { error: "strava_api" } });
      } else {
        setRoastResult(
          "Oops! Looks like we hit a wall. Please try again later."
        );
      }
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const startLoadingAnimation = () => {
    let index = 0;
    loadingIntervalRef.current = setInterval(() => {
      setLoadingEmoji(athleticEmojis[index]);
      index = (index + 1) % athleticEmojis.length;
    }, 200);
  };

  const stopLoadingAnimation = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current);
      loadingIntervalRef.current = null;
      setLoadingEmoji("🏃‍♂️"); // Reset to initial emoji if desired
    }
  };
  const handleShare = async () => {
    const textToShare =
      selectedFeature === "profile" ? roastResult : activityRoastResult;

    try {
      await navigator.clipboard.writeText(
        `I just got my Strava roasted by an AI...\n\n${textToShare}\n\nGet your Strava roasted at https://roastmystrava.com`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      // console.error("Failed to copy: ", err);
    }
  };

  const handleSaveRoast = () => {
    // Find the active roast container
    const targetContainer =
      selectedFeature === "profile"
        ? document.querySelector(".roast-container .roast-result-container")
        : document.querySelector(".roast-container .roast-result-container");

    if (targetContainer) {
      const scale = 10; // High quality scale
      const padding = 40 * scale;

      html2canvas(targetContainer, {
        useCORS: true,
        allowTaint: false,
        scale: scale,
        logging: false,
        backgroundColor: null, // Transparent background
      }).then((canvas) => {
        // Create a new canvas with padding
        const newCanvas = document.createElement("canvas");
        const context = newCanvas.getContext("2d");
        newCanvas.width = canvas.width + padding * 2;
        newCanvas.height = canvas.height + padding * 2;

        // Fill the entire canvas with Strava orange background
        context.fillStyle = "#FC4C02";
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the original canvas onto the new canvas with padding
        context.drawImage(canvas, padding, padding);

        // Create download link
        const link = document.createElement("a");
        const roastType =
          selectedFeature === "profile" ? "profile" : "activity";
        link.download = `roastmystrava_${roastType}_${
          userProfile.firstname?.toLowerCase() || ""
        }${userProfile.firstname && userProfile.lastname ? "_" : ""}${
          userProfile.lastname?.toLowerCase() || ""
        }_${formatDate(new Date())}.png`;
        link.href = newCanvas.toDataURL("image/png");
        link.click();
      });
    }
  };

  const formatDate = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (loadingIntervalRef.current) {
        clearInterval(loadingIntervalRef.current);
      }
    };
  }, []);

  // Activity roast functions
  const fetchActivities = async () => {
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/get_activities",
        {
          params: { token },
          withCredentials: true,
        }
      );
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  // Update the click outside handler to handle both dropdowns
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        activityDropdownRef.current &&
        !activityDropdownRef.current.contains(event.target)
      ) {
        setActivityDropdownOpen(false);
      }
      if (
        titleDropdownRef.current &&
        !titleDropdownRef.current.contains(event.target)
      ) {
        setTitleDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Modify the handleActivityChange function to work with custom dropdown
  const handleActivityChange = (activityId, fromTitleDropdown = false) => {
    // Check if the search option was selected
    if (activityId === "search") {
      setShowActivitySearch(true);
      if (fromTitleDropdown) {
        setTitleDropdownOpen(false);
      } else {
        setActivityDropdownOpen(false);
      }
      return;
    }

    setSelectedActivity(activityId);

    // Close the appropriate dropdown
    if (fromTitleDropdown) {
      setTitleDropdownOpen(false);
    } else {
      setActivityDropdownOpen(false);
    }

    // Store the selected activity details
    if (activityId && activities.length > 0) {
      const activityDetails = activities.find(
        (a) => a.id.toString() === activityId.toString()
      );
      setSelectedActivityDetails(activityDetails);
    } else {
      setSelectedActivityDetails(null);
    }
  };

  // Function to get selected activity name for display in dropdown
  const getSelectedActivityName = () => {
    if (!selectedActivity) return "Select an activity";

    const activity = activities.find(
      (a) => a.id.toString() === selectedActivity.toString()
    );

    if (!activity) return "Select an activity";

    const date = new Date(activity.start_date).toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    return `${date} | ${activity.type} | ${activity.name}`;
  };

  const handleActivityRoast = async () => {
    if (!selectedActivity) return;

    // Debug logging for activities
    // console.log("Selected Activity ID:", selectedActivity);
    // console.log("Activities Array:", activities);
    // console.log(
    //   "Activity being used:",
    //   activities.find((a) => a.id.toString() === selectedActivity.toString())
    // );

    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/analyze_activity",
        {
          params: {
            token,
            activity_id: selectedActivity,
            intensity: isActivityExtraMeanMode ? 10 : roastIntensity,
            unitPreference,
            model: isActivityExtraMeanMode ? "x" : "default",
          },
          withCredentials: true,
        }
      );
      setActivityRoastResult(response.data.message);
    } catch (error) {
      console.error("Error roasting activity:", error);
      setActivityRoastResult(
        "Oops! Something went wrong. Please try again later."
      );
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const handleKeywordsChange = (event) => {
    setKeywords(event.target.value);
  };

  const handleGenerateTitle = async () => {
    if (!selectedActivity) return;

    setLoading(true);
    startLoadingAnimation();
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/generate_title",
        {
          params: {
            token,
            activity_id: selectedActivity,
            style: titleStyle,
            randomness: titleRandomness,
            keywords: keywords,
            unitPreference,
          },
          withCredentials: true,
        }
      );
      setGeneratedTitle(response.data.title);
    } catch (error) {
      console.error("Error generating title:", error);
      setGeneratedTitle("Oops! Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
      stopLoadingAnimation();
    }
  };

  const handleCopyTitle = () => {
    navigator.clipboard
      .writeText(generatedTitle)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Load activities on page load once only if user is authenticated
  useEffect(() => {
    if (userProfile) {
      fetchActivities();
    }
  }, [userProfile]);

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Check for selectedFeature in location state when component mounts
  useEffect(() => {
    if (location.state && location.state.selectedFeature) {
      setSelectedFeature(location.state.selectedFeature);
      // Clear the state to prevent it from persisting on refresh
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  // Add a function to handle activity search
  const handleActivitySearch = (event) => {
    setActivitySearchTerm(event.target.value);
  };

  // Add a function to search for an activity by ID
  const searchActivityById = async () => {
    if (!activitySearchTerm) return;

    const trimmedSearchTerm = activitySearchTerm.trim();

    // First check if the activity is in our local list
    const matchingActivity = activities.find(
      (activity) => activity.id.toString() === trimmedSearchTerm
    );

    if (matchingActivity) {
      setSelectedActivity(matchingActivity.id.toString());
      setSelectedActivityDetails(matchingActivity);
      setShowActivitySearch(false);
    } else {
      // If not found locally, try to fetch it from the Strava API
      try {
        setLoading(true);
        startLoadingAnimation();
        const token = localStorage.getItem("strava_token");
        const response = await axios.get(
          "https://roast-my-strava-backend-production.up.railway.app/get_activity",
          {
            params: {
              token,
              activity_id: trimmedSearchTerm,
            },
            withCredentials: true,
          }
        );

        // If successful, add the activity to our state and select it
        if (response.data && response.data.id) {
          const fetchedActivity = response.data;

          // Add the fetched activity to our activities list
          setActivities((prevActivities) => [
            ...prevActivities,
            fetchedActivity,
          ]);

          // Select the fetched activity
          setSelectedActivity(fetchedActivity.id.toString());
          setSelectedActivityDetails(fetchedActivity);
          setShowActivitySearch(false);
        }
        setLoading(false);
        stopLoadingAnimation();
      } catch (error) {
        setLoading(false);
        stopLoadingAnimation();
        console.error("Error fetching activity by ID:", error);
        alert(
          "No activity found with that ID or you don't have permission to access it"
        );
      }
    }
  };

  // Add a function to close the search box
  const closeActivitySearch = () => {
    setShowActivitySearch(false);
    setActivitySearchTerm("");
  };

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Roast My Strava | Dashboard</title>
        <meta
          name="description"
          content="Connect your Strava account and get witty, AI-generated commentary on your Strava profile and activities. A fun way to celebrate your running, cycling, and other activities!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <a
        href="https://www.strava.com/athletes/jasonkuperberg"
        target="_blank"
        rel="noopener noreferrer"
        className="strava-follow-link"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "24px",
        }}
      >
        <button className="strava-follow-button">
          by jason 🏃🏻‍♂️ connect with me on strava
        </button>
      </a>
      {/* <h1 className="roast-header">Roast My Strava</h1> */}

      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {[userProfile.city, userProfile.state, userProfile.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>

      {userProfile && (
        <div
          className="feature-selector-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            backgroundColor: "#f0f0f0",
            borderRadius: "20px",
            padding: "3px",
            width: "90%",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: loading ? 0.7 : 1,
          }}
        >
          <button
            className={`feature-button ${
              selectedFeature === "profile" ? "active" : ""
            }`}
            onClick={() => {
              if (loading) return; // Prevent actions while loading
              setSelectedFeature("profile");
              // Reset other feature states
              setSelectedActivity("");
              setSelectedActivityDetails(null);
              setActivityRoastResult("");
              setGeneratedTitle("");
            }}
            style={{
              flex: 1,
              padding: "10px 12px",
              border: "none",
              backgroundColor:
                selectedFeature === "profile" ? "#FC4C02" : "transparent",
              color: selectedFeature === "profile" ? "white" : "#333",
              cursor: loading ? "not-allowed" : "pointer",
              borderRadius: "17px",
              transition: "background-color 0.3s, color 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              fontSize: "0.9em",
            }}
          >
            <span role="img" aria-label="Profile">
              👤
            </span>
            Profile Roast
          </button>
          <button
            className={`feature-button ${
              selectedFeature === "activity" ? "active" : ""
            }`}
            onClick={() => {
              if (loading) return; // Prevent actions while loading
              setSelectedFeature("activity");
              // Reset other feature states
              setRoastResult("");
              setActivityRoastResult("");
              setGeneratedTitle("");
              setSelectedActivity("");
              setSelectedActivityDetails(null);
            }}
            style={{
              flex: 1,
              padding: "10px 12px",
              border: "none",
              backgroundColor:
                selectedFeature === "activity" ? "#FC4C02" : "transparent",
              color: selectedFeature === "activity" ? "white" : "#333",
              cursor: loading ? "not-allowed" : "pointer",
              borderRadius: "17px",
              transition: "background-color 0.3s, color 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              fontSize: "0.9em",
            }}
          >
            <span role="img" aria-label="Activity">
              🏋️‍♀️
            </span>
            Activity Roast
          </button>
          {/* hidden on mobile */}
          {windowWidth > 600 && (
            <button
              className={`feature-button ${
                selectedFeature === "title" ? "active" : ""
              }`}
              onClick={() => {
                setSelectedFeature("title");
                // Reset other feature states
                setRoastResult("");
                setActivityRoastResult("");
                setGeneratedTitle("");
                setSelectedActivity("");
                setSelectedActivityDetails(null);
              }}
              style={{
                flex: 1,
                padding: "10px 12px",
                border: "none",
                backgroundColor:
                  selectedFeature === "title" ? "#FC4C02" : "transparent",
                color: selectedFeature === "title" ? "white" : "#333",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
            >
              <span role="img" aria-label="Title Generator">
                ✏️
              </span>
              Title Generator
            </button>
          )}
        </div>
      )}

      {userProfile && selectedFeature === "profile" && (
        <div className="roast-container">
          {hasGeneratedFirstRoast && (
            <div
              className="mean-mode-container"
              style={{
                marginBottom: "1.5rem",
                textAlign: "center",
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            >
              <h3
                style={{
                  color: "#666",
                  marginTop: "0",
                  marginBottom: "0.5rem",
                }}
              >
                Want an even spicier roast?
              </h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "0.5rem",
                  flexDirection: "column",
                }}
              >
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={isProfileExtraMeanMode}
                    disabled={loading}
                    onChange={(e) => {
                      setIsProfileExtraMeanMode(e.target.checked);
                      setRoastResult(""); // Clear roast results when mode is toggled
                    }}
                  />
                  <span className="toggle-slider"></span>
                </label>
                {isProfileExtraMeanMode && (
                  <span style={{ color: "red" }}>
                    ⚠️ Intense Mode Activated ⚠️
                  </span>
                )}
              </div>
              {isProfileExtraMeanMode && (
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "#666",
                    maxWidth: "80%",
                    margin: "0 auto",
                    fontStyle: "italic",
                  }}
                >
                  Warning: We cannot be held responsible for potentially
                  offensive content generated in this mode.
                </p>
              )}
            </div>
          )}

          {/* Year Roast Mode Container - Commented out
          <div
            className="year-roast-container"
            style={{ marginBottom: "1.5rem", textAlign: "center" }}
          >
            <h3
              style={{
                color: "#666",
                marginTop: "0",
                marginBottom: "0.5rem",
              }}
            >
              {currentYear} Strava Roasted
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "0.5rem",
                flexDirection: "column",
              }}
            >
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={isYearRoastMode}
                  onChange={(e) => {
                    setIsYearRoastMode(e.target.checked);
                    setRoastResult("");
                  }}
                />
                <span className="toggle-slider"></span>
              </label>
              {isYearRoastMode && (
                <span style={{ color: "#FC4C02" }}>
                  🎯 Year Review Roast Mode Activated
                </span>
              )}
            </div>
          </div>
          */}
          <div
            className="unit-preference-switcher"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
              backgroundColor: "#f0f0f0",
              borderRadius: "20px",
              padding: "3px",
              width: "280px",
              marginLeft: "auto",
              marginRight: "auto",
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <button
              className={`switcher-button ${
                unitPreference === "miles" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("miles")}
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "miles" ? "#FC4C02" : "transparent",
                color: unitPreference === "miles" ? "white" : "#333",
                cursor: loading ? "not-allowed" : "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
            >
              <span
                role="img"
                aria-label="US Flag"
                style={{ fontSize: "1.2em" }}
              >
                🇺🇸
              </span>
              Miles
            </button>
            <button
              className={`switcher-button ${
                unitPreference === "kilometers" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("kilometers")}
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "kilometers" ? "#FC4C02" : "transparent",
                color: unitPreference === "kilometers" ? "white" : "#333",
                cursor: loading ? "not-allowed" : "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
            >
              <span role="img" aria-label="World" style={{ fontSize: "1.2em" }}>
                🌍
              </span>
              Kilometers
            </button>
          </div>

          <div
            className="intensity-slider-container"
            style={{
              opacity: isProfileExtraMeanMode || loading ? 0.5 : 1,
              pointerEvents:
                isProfileExtraMeanMode || loading ? "none" : "auto",
            }}
          >
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={isProfileExtraMeanMode ? 10 : roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
                disabled={isProfileExtraMeanMode || loading}
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>
          <button
            onClick={handleRoast}
            disabled={loading}
            className="roast-button"
          >
            {loading
              ? `Generating Roast... ${loadingEmoji}`
              : "Roast My Strava 🔥"}
          </button>
          {roastResult && (
            <>
              <div className="roast-result-container">
                {/* Year roast header - Commented out
                {isYearRoastMode && (
                  <div
                    className="year-roast-header"
                    style={{
                      fontSize: "1.5em",
                      fontWeight: "bold",
                      marginBottom: "0.6rem",
                      color: "#FC4C02",
                      textAlign: "center",
                    }}
                  >
                    {currentYear} Strava Roasted
                  </div>
                )}
                */}
                <div className="roast-result-text">{roastResult}</div>
                <div className="roast-result-footer">
                  <div className="user-profile-mini">
                    <img
                      src={userProfile.profile}
                      alt={`${userProfile.firstname}'s profile`}
                      className="mini-profile-image"
                      crossOrigin="anonymous" // Added crossOrigin attribute
                    />
                    <div className="user-info-mini">
                      <span className="user-name-mini">
                        {[userProfile.firstname, userProfile.lastname]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                      <span className="user-location-mini">
                        {[userProfile.city, userProfile.state]
                          .filter(Boolean)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div className="roast-source-container">
                    <div
                      className="roast-source"
                      style={
                        isProfileExtraMeanMode && window.innerWidth > 768
                          ? { fontSize: "1.2em" }
                          : {}
                      }
                    >
                      RoastMyStrava.com
                    </div>
                    {!isProfileExtraMeanMode && (
                      <img
                        src={poweredByStrava}
                        alt="Powered by Strava"
                        className="powered-by-strava"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
                <button onClick={handleSaveRoast}>Save Roast 💾</button>
              </div>
            </>
          )}
        </div>
      )}

      {userProfile && selectedFeature === "activity" && (
        <div className="roast-container">
          <div
            className="activity-selector"
            style={{
              marginBottom: "20px",
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
              position: "relative",
              maxWidth: "100%",
              zIndex: 10,
            }}
          >
            {showActivitySearch ? (
              <div
                className="activity-search-container"
                style={{
                  width: "100%",
                  maxWidth: "min(420px, 80vw)",
                  margin: "0 auto",
                }}
              >
                <input
                  type="text"
                  value={activitySearchTerm}
                  onChange={handleActivitySearch}
                  onKeyPress={(e) =>
                    e.key === "Enter" && !loading && searchActivityById()
                  }
                  placeholder="Enter Activity ID"
                  className="activity-search-input"
                />
                <div className="activity-search-buttons">
                  <button
                    onClick={searchActivityById}
                    className="search-button"
                    disabled={loading}
                  >
                    {loading ? `${loadingEmoji} Searching...` : "Search"}
                  </button>
                  <button
                    onClick={closeActivitySearch}
                    className="cancel-button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="custom-dropdown-container"
                ref={activityDropdownRef}
                style={{
                  width: "100%",
                  maxWidth: "min(420px, 80vw)",
                  margin: "0 auto",
                }}
              >
                <div
                  className="custom-dropdown-selected"
                  onClick={() =>
                    !loading && setActivityDropdownOpen(!activityDropdownOpen)
                  }
                  style={{
                    padding: "12px 15px",
                    borderRadius: "5px",
                    border: "1px solid #FC4C02",
                    backgroundColor: "#fff",
                    fontSize: "16px",
                    color: selectedActivity ? "#333" : "#666",
                    cursor: loading ? "not-allowed" : "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: loading ? 0.7 : 1,
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {getSelectedActivityName()}
                  </span>
                  <svg
                    fill="#FC4C02"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    style={{
                      transform: activityDropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s ease",
                      flexShrink: 0,
                    }}
                  >
                    <path d="M7 10l5 5 5-5z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </div>

                {activityDropdownOpen && (
                  <div
                    className="custom-dropdown-menu"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0 0 5px 5px",
                      zIndex: 20,
                      border: "1px solid #ddd",
                      borderTop: "none",
                    }}
                  >
                    <div
                      className="custom-dropdown-item search-option"
                      onClick={() => handleActivityChange("search")}
                      style={{
                        padding: "10px 15px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f5f5f5",
                        cursor: "pointer",
                        color: "#555",
                        boxSizing: "border-box",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      🔍 Search Activity by ID
                    </div>

                    {activities.map((activity, index) => {
                      const date = new Date(
                        activity.start_date
                      ).toLocaleDateString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      });
                      return (
                        <div
                          key={activity.id}
                          className="custom-dropdown-item"
                          onClick={() =>
                            handleActivityChange(activity.id.toString())
                          }
                          style={{
                            padding: "10px 15px",
                            borderBottom: "1px solid #eee",
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#fff" : "#f8f8f8",
                            color:
                              selectedActivity === activity.id.toString()
                                ? "#FC4C02"
                                : "#333",
                            fontWeight:
                              selectedActivity === activity.id.toString()
                                ? "bold"
                                : "normal",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            boxSizing: "border-box",
                            width: "100%",
                          }}
                        >
                          {date} | {activity.type} | {activity.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            className="unit-preference-switcher"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
              backgroundColor: "#f0f0f0",
              borderRadius: "20px",
              padding: "3px",
              width: "280px",
              marginLeft: "auto",
              marginRight: "auto",
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <button
              className={`switcher-button ${
                unitPreference === "miles" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("miles")}
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "miles" ? "#FC4C02" : "transparent",
                color: unitPreference === "miles" ? "white" : "#333",
                cursor: loading ? "not-allowed" : "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
            >
              <span
                role="img"
                aria-label="US Flag"
                style={{ fontSize: "1.2em" }}
              >
                🇺🇸
              </span>
              Miles
            </button>
            <button
              className={`switcher-button ${
                unitPreference === "kilometers" ? "active" : ""
              }`}
              onClick={() => setUnitPreference("kilometers")}
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "kilometers" ? "#FC4C02" : "transparent",
                color: unitPreference === "kilometers" ? "white" : "#333",
                cursor: loading ? "not-allowed" : "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
            >
              <span role="img" aria-label="World" style={{ fontSize: "1.2em" }}>
                🌍
              </span>
              Kilometers
            </button>
          </div>

          <div
            className="intensity-slider-container"
            style={{
              opacity: isActivityExtraMeanMode || loading ? 0.5 : 1,
              pointerEvents:
                isActivityExtraMeanMode || loading ? "none" : "auto",
              marginBottom: "20px",
            }}
          >
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={isActivityExtraMeanMode ? 10 : roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
                disabled={isActivityExtraMeanMode || loading}
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>

          <button
            onClick={handleActivityRoast}
            disabled={!selectedActivity || loading}
            className="roast-button"
          >
            {loading
              ? `Generating Roast... ${loadingEmoji}`
              : "Roast My Activity 🔥"}
          </button>

          {activityRoastResult && (
            <>
              <div className="roast-result-container">
                {/* Add activity stats section at the top of the roast */}
                <div
                  className="activity-stats-header"
                  style={{
                    borderBottom: "1px solid #eee",
                    marginBottom: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  {selectedActivityDetails ? (
                    <>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          marginBottom: "2px",
                          color: "#FC4C02",
                        }}
                      >
                        {selectedActivityDetails.name}
                      </div>
                      <div style={{ fontSize: "0.9em", color: "#666" }}>
                        {[
                          selectedActivityDetails.type,
                          selectedActivityDetails.distance &&
                            `${(unitPreference === "miles"
                              ? selectedActivityDetails.distance / 1609.34
                              : selectedActivityDetails.distance / 1000
                            ).toFixed(2)} ${
                              unitPreference === "miles" ? "mi" : "km"
                            }`,
                          selectedActivityDetails.moving_time &&
                            (() => {
                              const hours = Math.floor(
                                selectedActivityDetails.moving_time / 3600
                              );
                              const minutes = Math.floor(
                                (selectedActivityDetails.moving_time % 3600) /
                                  60
                              );
                              const seconds =
                                selectedActivityDetails.moving_time % 60;
                              return `${
                                hours > 0 ? hours + "h " : ""
                              }${minutes}m ${seconds}s`;
                            })(),
                          selectedActivityDetails.start_date &&
                            new Date(
                              selectedActivityDetails.start_date
                            ).toLocaleDateString(),
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      </div>
                    </>
                  ) : (
                    <div style={{ fontStyle: "italic", color: "#666" }}>
                      Activity Stats
                    </div>
                  )}
                </div>
                <div className="roast-result-text">{activityRoastResult}</div>
                <div className="roast-result-footer">
                  <div className="user-profile-mini">
                    <img
                      src={userProfile.profile}
                      alt={`${userProfile.firstname}'s profile`}
                      className="mini-profile-image"
                      crossOrigin="anonymous"
                    />
                    <div className="user-info-mini">
                      <span className="user-name-mini">
                        {[userProfile.firstname, userProfile.lastname]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                      <span className="user-location-mini">
                        {[userProfile.city, userProfile.state]
                          .filter(Boolean)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div className="roast-source-container">
                    <div
                      className="roast-source"
                      style={
                        isActivityExtraMeanMode && window.innerWidth > 768
                          ? { fontSize: "1.2em" }
                          : {}
                      }
                    >
                      RoastMyStrava.com
                    </div>
                    {!isActivityExtraMeanMode && (
                      <img
                        src={poweredByStrava}
                        alt="Powered by Strava"
                        className="powered-by-strava"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
                <button onClick={handleSaveRoast}>Save Roast 💾</button>
              </div>
            </>
          )}
        </div>
      )}

      {userProfile && selectedFeature === "title" && (
        <div className="roast-container">
          <div
            className="activity-selector"
            style={{
              marginBottom: "20px",
              position: "relative",
              maxWidth: "100%",
              zIndex: 10,
            }}
          >
            {showActivitySearch ? (
              <div
                className="activity-search-container"
                style={{
                  width: "100%",
                  maxWidth: "min(420px, 80vw)",
                  margin: "0 auto",
                }}
              >
                <input
                  type="text"
                  value={activitySearchTerm}
                  onChange={handleActivitySearch}
                  onKeyPress={(e) =>
                    e.key === "Enter" && !loading && searchActivityById()
                  }
                  placeholder="Enter Activity ID"
                  className="activity-search-input"
                />
                <div className="activity-search-buttons">
                  <button
                    onClick={searchActivityById}
                    className="search-button"
                    disabled={loading}
                  >
                    {loading ? `${loadingEmoji} Searching...` : "Search"}
                  </button>
                  <button
                    onClick={closeActivitySearch}
                    className="cancel-button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="custom-dropdown-container"
                ref={titleDropdownRef}
                style={{
                  width: "100%",
                  maxWidth: "min(420px, 80vw)",
                  margin: "0 auto",
                }}
              >
                <div
                  className="custom-dropdown-selected"
                  onClick={() => setTitleDropdownOpen(!titleDropdownOpen)}
                  style={{
                    padding: "12px 15px",
                    borderRadius: "5px",
                    border: "1px solid #FC4C02",
                    backgroundColor: "#fff",
                    fontSize: "16px",
                    color: selectedActivity ? "#333" : "#666",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {getSelectedActivityName()}
                  </span>
                  <svg
                    fill="#FC4C02"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    style={{
                      transform: titleDropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s ease",
                      flexShrink: 0,
                    }}
                  >
                    <path d="M7 10l5 5 5-5z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </div>

                {titleDropdownOpen && (
                  <div
                    className="custom-dropdown-menu"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0 0 5px 5px",
                      zIndex: 20,
                      border: "1px solid #ddd",
                      borderTop: "none",
                    }}
                  >
                    <div
                      className="custom-dropdown-item search-option"
                      onClick={() => handleActivityChange("search", true)}
                      style={{
                        padding: "10px 15px",
                        borderBottom: "1px solid #ddd",
                        backgroundColor: "#f5f5f5",
                        cursor: "pointer",
                        color: "#555",
                        boxSizing: "border-box",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      🔍 Search Activity by ID
                    </div>

                    {activities.map((activity, index) => {
                      const date = new Date(
                        activity.start_date
                      ).toLocaleDateString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      });
                      return (
                        <div
                          key={activity.id}
                          className="custom-dropdown-item"
                          onClick={() =>
                            handleActivityChange(activity.id.toString(), true)
                          }
                          style={{
                            padding: "10px 15px",
                            borderBottom: "1px solid #eee",
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#fff" : "#f8f8f8",
                            color:
                              selectedActivity === activity.id.toString()
                                ? "#FC4C02"
                                : "#333",
                            fontWeight:
                              selectedActivity === activity.id.toString()
                                ? "bold"
                                : "normal",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            boxSizing: "border-box",
                            width: "100%",
                          }}
                        >
                          {date} | {activity.type} | {activity.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* only show this for the profiler roast and activity roast */}
          {(selectedFeature === "profile" ||
            selectedFeature === "activity") && (
            <div
              className="unit-preference-switcher"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
                backgroundColor: "#f0f0f0",
                borderRadius: "20px",
                padding: "3px",
                width: "280px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <button
                className={`switcher-button ${
                  unitPreference === "miles" ? "active" : ""
                }`}
                onClick={() => setUnitPreference("miles")}
                style={{
                  flex: 1,
                  padding: "8px 12px",
                  border: "none",
                  backgroundColor:
                    unitPreference === "miles" ? "#FC4C02" : "transparent",
                  color: unitPreference === "miles" ? "white" : "#333",
                  cursor: "pointer",
                  borderRadius: "17px",
                  transition: "background-color 0.3s, color 0.3s",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "0.9em",
                }}
              >
                <span
                  role="img"
                  aria-label="US Flag"
                  style={{ fontSize: "1.2em" }}
                >
                  🇺🇸
                </span>
                Miles
              </button>
              <button
                className={`switcher-button ${
                  unitPreference === "kilometers" ? "active" : ""
                }`}
                onClick={() => setUnitPreference("kilometers")}
                style={{
                  flex: 1,
                  padding: "8px 12px",
                  border: "none",
                  backgroundColor:
                    unitPreference === "kilometers" ? "#FC4C02" : "transparent",
                  color: unitPreference === "kilometers" ? "white" : "#333",
                  cursor: "pointer",
                  borderRadius: "17px",
                  transition: "background-color 0.3s, color 0.3s",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: "0.9em",
                }}
              >
                <span
                  role="img"
                  aria-label="World"
                  style={{ fontSize: "1.2em" }}
                >
                  🌍
                </span>
                Kilometers
              </button>
            </div>
          )}

          <div
            className="title-options"
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="keywords-input">
              <h3
                style={{
                  marginBottom: "10px",
                  color: "#333",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Keywords or Instructions (Optional)
              </h3>
              <input
                type="text"
                value={keywords}
                onChange={handleKeywordsChange}
                placeholder="e.g., sunset, hills, endurance..."
                className="keywords-field"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                  fontSize: "16px",
                }}
              />
            </div>
          </div>

          <button
            onClick={handleGenerateTitle}
            disabled={!selectedActivity || loading}
            className="roast-button"
          >
            {loading
              ? `Generating Title... ${loadingEmoji}`
              : "Generate Title ✨"}
          </button>

          {generatedTitle && (
            <div
              className="title-result"
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                margin: "20px auto",
                maxWidth: "600px",
                textAlign: "center",
              }}
            >
              <div
                className="title-display"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  padding: "15px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "5px",
                  marginBottom: "20px",
                  lineHeight: "1.4",
                }}
              >
                {generatedTitle}
              </div>
              <button
                onClick={handleCopyTitle}
                className="copy-title-button"
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 15px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {copySuccess ? "Copied! 📋" : "Copy Title 📝"}
              </button>
            </div>
          )}
        </div>
      )}

      {userProfile && (
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
          {/* <a
            href="https://www.strava.com/athletes/jasonkuperberg"
            target="_blank"
            rel="noopener noreferrer"
            className="strava-follow-link"
          >
            <button className="strava-follow-button">
              by jason 🏃🏻‍♂️ connect with me on strava{" "}
            </button>
          </a> */}
        </div>
      )}
      {!(
        (selectedFeature === "profile" && isProfileExtraMeanMode) ||
        (selectedFeature === "activity" && isActivityExtraMeanMode)
      ) && (
        <img
          src={poweredByStrava}
          alt="Powered by Strava"
          style={{
            width: "120px",
            display: "block",
            margin: "12px auto",
          }}
        />
      )}
    </div>
  );
}

export default Dashboard;
