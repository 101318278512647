import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import NewHome from "./components/NewHome";
import ProfileRoastLanding from "./components/ProfileRoastLanding";
import ActivityRoastLanding from "./components/ActivityRoastLanding";
import TitleGeneratorLanding from "./components/TitleGeneratorLanding";
// import Chat from "./components/Chat";
function RedirectToExternalUrl({ url }) {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
}

// Component to redirect to Dashboard with specific feature selected
function RedirectToDashboard({ feature }) {
  return <Navigate to="/dashboard" state={{ selectedFeature: feature }} />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NewHome />} />
        <Route path="/dashboard" element={<Dashboard />} />

        {/* New landing pages */}
        <Route path="/strava-profile-roast" element={<ProfileRoastLanding />} />
        <Route
          path="/strava-activity-roast"
          element={<ActivityRoastLanding />}
        />
        <Route
          path="/strava-title-generator"
          element={<TitleGeneratorLanding />}
        />

        <Route
          path="/activity-roast"
          element={<RedirectToDashboard feature="activity" />}
        />
        <Route
          path="/activity"
          element={<RedirectToDashboard feature="activity" />}
        />
        <Route
          path="/title-generator"
          element={<RedirectToDashboard feature="title" />}
        />
        {/* <Route path="/chat" element={<Chat />} /> */}
        <Route
          path="/about"
          element={
            <RedirectToExternalUrl url="https://jasonkuperberg.com/roast-my-strava" />
          }
        />
        <Route path="/home" element={<NewHome />} />
        <Route path="/old" element={<Home />} />
        {/* any other link should redirect to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
