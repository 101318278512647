import React from "react";
import { Helmet } from "react-helmet";
import ToolLandingTemplate from "./ToolLandingTemplate";

const ProfileRoastLanding = () => {
  const metaTitle = "Strava Profile Roast | AI Powered Strava Profile Analysis";
  const metaDescription =
    "Get personalized insights and humorous commentary on your Strava profile. Our AI analyzes your activities, achievements, and patterns to provide a unique perspective on your athletic journey.";

  const title = "Strava Profile Roast";
  const description =
    "Get personalized insights and humorous commentary on your Strava profile. Our AI analyzes your activities, achievements, and patterns to provide a unique perspective on your athletic journey.";

  const howItWorks = [
    {
      title: "Connect Your Strava",
      description: "Log in to securely link your Strava account.",
    },
    {
      title: "AI Analysis",
      description:
        "Our AI examines your profile, activities, and achievements.",
    },
    {
      title: "Get Your Roast",
      description:
        "Receive a personalized, entertaining analysis of your Strava profile.",
    },
  ];

  const faqs = [];

  const otherTools = [
    {
      name: "Activity Roast",
      shortDescription:
        "Get AI commentary on your individual Strava activities",
      path: "/strava-activity-roast",
    },
    {
      name: "Title Generator",
      shortDescription:
        "Create engaging, personalized titles for your Strava activities",
      path: "/strava-title-generator",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <ToolLandingTemplate
        title={title}
        description={description}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        howItWorks={howItWorks}
        howItWorksTitle="How the Strava Profile Roast Works"
        faqs={faqs}
        otherTools={otherTools}
        tryItText="Get Roasted"
      />
    </>
  );
};

export default ProfileRoastLanding;
