import React from "react";
import { Helmet } from "react-helmet";
import ToolLandingTemplate from "./ToolLandingTemplate";

const TitleGeneratorLanding = () => {
  const metaTitle =
    "Strava Title Generator | Creative Strava Activity Titles with AI";
  const metaDescription =
    "Never struggle with naming your activities again. Our AI generates creative, personalized titles for your Strava activities based on your performance, route, and more.";

  const title = "Strava Title Generator";
  const description =
    "Never struggle with naming your activities again. Our AI generates creative, personalized titles for your Strava activities based on your performance, route, and more.";

  const howItWorks = [
    {
      title: "Connect Your Strava",
      description: "Log in to securely link your Strava account.",
    },
    {
      title: "Select an Activity",
      description:
        "Choose any activity from your Strava history that needs a title.",
    },
    {
      title: "Generate Titles",
      description:
        "Get multiple creative Strava activity title options tailored to your activity.",
    },
  ];

  const faqs = [];

  const otherTools = [
    {
      name: "Profile Roast",
      shortDescription:
        "Get AI commentary on your entire Strava profile and all-time stats",
      path: "/strava-profile-roast",
    },
    {
      name: "Activity Roast",
      shortDescription:
        "Get AI commentary on your individual Strava activities",
      path: "/strava-activity-roast",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <ToolLandingTemplate
        title={title}
        description={description}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        howItWorks={howItWorks}
        howItWorksTitle="How the Strava Title Generator Works"
        faqs={faqs}
        otherTools={otherTools}
        tryItText="Generate Titles"
      />
    </>
  );
};

export default TitleGeneratorLanding;
